import React from 'react'
import { graphql } from "gatsby";

import Demo from '../components/Demo/Demo'
import NotFound from './404'

export default function DemoPage(props) {

  const { data: { demo } } = props

  if (!demo) return <NotFound />

  return <Demo images={demo} />
}

export const query = graphql`
    query DemoQuery($slug: String!) {
        demo: strapiDemo(slug: { eq: $slug }) {
         title
         slug
         mobileImages {
           localFile {
             childImageSharp {
               gatsbyImageData(
                 formats: [WEBP, AUTO]
                 quality: 100
                 webpOptions: {quality: 100}
                 jpgOptions: {progressive: true, quality: 100}
               )
             }
           }
         }
         desktopImages {
           localFile {
             childImageSharp {
               gatsbyImageData(
                 formats: [WEBP, AUTO]
                 quality: 100
                 webpOptions: {quality: 100}
                 jpgOptions: {progressive: true, quality: 100}
               )
             }
           }
         }
       }
    }
`;


// export const query = graphql`
//   query DemosQuery {
//     demos: allStrapiDemo {
//       nodes {
//         title
//         slug
//         mobileImages {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(
//                 formats: [WEBP, AUTO]
//                 quality: 100
//                 webpOptions: {quality: 100}
//                 jpgOptions: {progressive: true, quality: 100}
//               )
//             }
//           }
//         }
//         desktopImages {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(
//                 formats: [WEBP, AUTO]
//                 quality: 100
//                 webpOptions: {quality: 100}
//                 jpgOptions: {progressive: true, quality: 100}
//               )
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// export const getServerData = async () => {
//   const res = await fetch(`http://localhost:1337/api/demos?${populateQuery}`)
//     .then(r => r.json())
//     .then(data => {
//       console.log(data)
//       return data
//     })
//     .catch(err => console.log('err', err))

//   console.log('!!!! RES: ', res)

//   return {
//     props: res
//   }
// }