import React from 'react'

import CompareImage from './CompareImage/CompareImage'

import * as styles from './Demo.module.scss'
import './global.css'

export default function Demo(props) {
  if (!props.images) return null

  const { desktopImages, mobileImages } = props.images

  const [beforeDesktop, afterDesktop] = desktopImages
  const [beforeMobile, afterMobile] = mobileImages

  return (
    <div className={styles.Wrapper}>
      <CompareImage
        leftImage={beforeDesktop.localFile.childImageSharp.gatsbyImageData}
        rightImage={afterDesktop.localFile.childImageSharp.gatsbyImageData}
        hover
        className={styles.DesktopComparator}
        mode='contain'
      />
      <CompareImage
        leftImage={beforeMobile.localFile.childImageSharp.gatsbyImageData}
        rightImage={afterMobile.localFile.childImageSharp.gatsbyImageData}
        hover
        className={styles.MobileComparator}
        mode='cover'
      />
    </div>
  )
}
